<template>
  <div>
    <v-app-bar
      id="home-app-bar"
      app
      elevation="1"
      elevate-on-scroll
      height="80"
    >
      <base-img
        src="./assets/img/BarLogo.png"
        contain
        max-width="250"
        width="100%"
        class="navimg"
        @click="$vuetify.goTo('#hero')"
      />

      <v-spacer />

      <div>
        <v-tabs
          class="hidden-sm-and-down"
          optional
          background-color="transparent"
        >
          <v-tab
            v-for="(feature, i) in features"
            :key="i"
            :exact="feature.name === 'Home'"
            :ripple="false"
            class="font-weight-bold"
            min-width="96"
            text
            @click="navigate(feature.id)"
          >
            {{ feature.name }}
          </v-tab>
        </v-tabs>
      </div>

      <!--<div>
        <v-tabs
          class="hidden-sm-and-down"
          optional
          background-color="transparent"
        >
          <v-tab
            v-for="(name, i) in items"
            :key="i"
            :to="{ name }"
            :exact="name === 'Home'"
            :ripple="false"
            class="font-weight-bold"
            min-width="96"
            text
          >
            {{ name }}
          </v-tab>
        </v-tabs>
      </div>-->

      <v-app-bar-nav-icon
        class="hidden-md-and-up"
        @click="drawer = !drawer"
      />
    </v-app-bar>

    <home-drawer
      v-model="drawer"
      :items="features"
    />
  </div>
</template>

<script>
  export default {
    name: 'HomeAppBar',

    components: {
      HomeDrawer: () => import('./Drawer'),
    },
    data: () => ({
      drawer: null,
      features: [{
                   name: 'Astrologie & Psychologie',
                   id: 'astropsychology',
                 },
                 {
                   name: 'Hypnosetherapie',
                   id: 'hynosetherapie',
                 },
                 {
                   name: 'Kurse & Seminare',
                   id: 'seminare',
                 },
                 {
                   name: 'News',
                   id: 'news',
                 },
                 {
                   name: 'Referenzen',
                   id: 'customer-reviews',
                 },
                 {
                   name: 'Preise',
                   id: 'pricing-plan',
                 },
                 {
                   name: 'Über mich',
                   id: 'team',
                 },
                 {
                   name: 'Kontakt',
                   id: 'info-alt',
                 },
      ],
      items: ['Home',
              'Kontakt'],
    }),
    methods: {
      navigate (id) {
        console.log(id)
        // First check if the current route is on landing page (or wherever your #foo is) then set the click function for it.
        if (this.$route.path !== '/') {
          this.$vuetify.goTo('#' + id)
        } else {
          this.$vuetify.goTo('#' + id)
        }
      },
    },
  }
</script>

<style lang="sass">
#home-app-bar
  .v-tabs-slider
    max-width: 24px
    margin: 0 auto

    .v-tab
      &::before
        display: none
#header-mb
  .header-mb
    color: black !important
    margin-top: auto
</style>
